import { Box, Flex, Image, Tag, Text } from '@chakra-ui/react'
import { useTranslation } from 'next-i18next'
import React from 'react'
import { OTFS } from 'constants/otfs'
import { useThemeColors } from 'hooks/useThemeColors'
import { getAssetImageSrc } from 'utils/getAssetImageSrc'

type AssetItemProps = {
  value?: string
  name: string
  symbol: string
  contract_address_l1?: string
}

export const AssetItem = ({ contract_address_l1, name, symbol, value }: AssetItemProps) => {
  const { t } = useTranslation(['common'])
  const COLORS = useThemeColors()

  return (
    <Flex alignItems="center">
      <Image src={getAssetImageSrc({ symbol, contract_address_l1 })} borderRadius="full" />
      <Box ml={3}>
        <Text variant="title2medium" mb={0.5}>
          {name}
        </Text>
        <Flex alignItems="center" gap={1.5}>
          <Text color={COLORS.grey02} variant="caption2medium">
            {symbol}
          </Text>
          {OTFS.find((otf) => otf.symbol === symbol) && <Tag>{t('External')}</Tag>}
        </Flex>
      </Box>
      <Text ml="auto" color={COLORS.grey02} variant="text2regular">
        {value || 0}
      </Text>
    </Flex>
  )
}
